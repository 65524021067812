import {lazy, Suspense} from 'react';
import {Navigate} from "react-router-dom";

// const Login = lazy(() => import('../page/Login'));
const Home = lazy(() => import('../page/Home'));
const RepairReport = lazy(() => import('../page/Home/RepairReport'));
const MyRepairReport = lazy(() => import('../page/Home/MyRepairReport'));
const Fallback = lazy(() => import('../components/loading'));
// const Button = lazy(() => import('../page/Button'));
// const Administrator = lazy( () => import('../page/Home/Administrator'));
// const Role = lazy(() => import('../page/Home/Role'));
// const RoleManage = lazy(() => import('../page/Home/RoleManage'));
// const Page = lazy(() => import('../page/Home/Page'));


const routes = [
    // {
    //     path: "/",
    //     name: "Home",
    //     auth: false,
    //     element: <Suspense fallback={<Fallback/>}><Home/></Suspense>,
    // },
    {
        path: "/",
        name: "Home",
        auth: false,
        element: <Suspense fallback={<Fallback/>}><Home/></Suspense>,
        children: [
            {
                path: "",
                name: "RepairReport",
                auth: false,
                element: <Suspense fallback={<Fallback/>}><RepairReport/></Suspense>,
            },
            {
                path: "myrepairreport",
                name: "MyRepairReport",
                auth: false,
                element: <Suspense fallback={<Fallback/>}><MyRepairReport/></Suspense>,
            },
        ]
    },
    {
        path: "/loading",
        element: <Suspense fallback={<Fallback/>}><Fallback/></Suspense>,
    },
    {
        path: "/401",
        element: <div>无权限...</div>
    },
    {
        path: "/404",
        element: <div>404...</div>
    },
    // {
    //     path: "*",
    //     element: <Suspense fallback={<Fallback/>}><Login/></Suspense>
    // },
    {
        path: "*",
        element: <Navigate to="/404"></Navigate>
    },
]
export default routes;